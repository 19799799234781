@import "../bower_components/theme/universal/styles/mixins";

.lp-navbar-advanced {
    .navbar > .container-fluid {
        width: auto;
    }

    .launcher-toggle {
        margin-left: 5px;
        width: auto;
    }
    .navbar-brand.active-page {
        float: none;
        margin: 0;
    }
    .launcher-toggle .badge {
        margin-left: 10px;
    }
}


.nav-icon.navbar-left .glyphicon.glyphicon-list {
	padding: 0 5px;
}

/**
 * Preset hamburger animation styles
 */

.lp-navbar-advanced {
    .animation-arrow span, .animation-none span {
        .transition(all 0.7s ease);
    }

    .animation-arrow {
        .animate {
            position: relative;
        }

        span.bar-first {
            .transform(rotate(90deg));
        }

        span.bar-second {
            .transform(rotate(-45deg));
            width: 70%;
            margin-left: 8px;
            margin-top: 4px;
        }

        span.bar-third {
            .transform(rotate(45deg));
            width: 70%;
            margin-left: -1px;
            margin-top: -2px;
        }
    }

    span.bar-first, span.bar-second, span.bar-third {
        .transform(rotate(0deg));
    }


    .lp-transparency-scroll {
        .transition(opacity 0.7s ease);
        opacity: 0.5;
    }

    .lp-hide-show-scroll {
        .transition(min-height 0.3s ease);
        min-height: 0;
        height: 0;
    }

    .lp-hide-show-scroll * {
        display: none;
    }
}



/**
 * Nested CSS Menu
 */



@media screen and (min-width: 767px) {
    .lp-navbar-advanced-root {
        & > li > ul {
            position: absolute;
            display: none;
            white-space: nowrap

            // & a {padding-left: 30px;}
        }

        & > li:hover ul {
            display: block;
        }
    }
}




// Add missing divider


.lp-navbar-advanced .divider-vertical a {
    height: 100%;
    display: block;
    border-right: 1px solid rgba(255,255,255,0.1);
    border-left: 1px solid rgba(0,0,0,0.1);
    width: 2px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
}


@media (max-width: 767px) {
.navbar-collapse .nav > .divider-vertical {
    display: none;
  }
}



// Profile information
.lp-navbar-advanced {
    .profile-info {

        margin-right: 0px;

        > li {
            height: 100%;
        }

        .card {

            height: 100%;
            white-space: nowrap;

            padding: 0 0 0 25px;

            img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
            }

            .info {
                margin: 0 5px;
                line-height: 1.5;
                display: inline-block;
                vertical-align: middle;

                .title {
                    font-weight: bold;
                    font-size: 15px;

                    a:not([href]) {
                        text-decoration: none;
                    }

                    :after {
                        background: none;
                    }
                }

                .name {

                }
            }

            .logout {
                padding: 0 12px 0 14px;
                font-size: 14px;
                margin-left: 7px;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 768px) {
        .container-fluid .profile-info {
            margin-right: -20px;
        }
    }

    .sign-in {
        .link {
            padding: 6px 15px;
            font-weight: 500;
            font-size: 14px;
            &:after {
                display: none;
            }
        }

        @media (max-width: 768px) {
            .link {
                display: block;
                padding: 10px 25px;
            }
        }
    }
}
